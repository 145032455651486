// self define color, which we will use in our code.

$heading: #005bbb;
$background-color: #ffffff;
$tertiary-color: #66e092;
$primary-color: #173d6a;

// font family

@font-face {
  font-family: Helvetica-Neue-Regular;
  src: url("../src/fonts/HelveticaNeueLt.ttf") format("truetype");
}
@font-face {
  font-family: Helvetica-Neue-Medium;
  src: url("../src/fonts/HelveticaNeueMedium.ttf") format("truetype");
}
@font-face {
  font-family: Helvetica-Neue-Bold;
  src: url("../src/fonts/HelveticaNeuBold.ttf") format("truetype");
}
@font-face {
  font-family: Acumin-Pro-Extra-Condensed;
  src: url("../src/fonts/AcuminProExtraCondensed.ttf") format("truetype");
}

html {
  scroll-behavior: smooth;
}
* {
  margin: 0rem;
  padding: 0rem;
  transition: all ease-in 0.5s;
}
::-webkit-scrollbar {
  width: 15px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 0px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $tertiary-color;
}
a {
  color: $heading !important;
}
.coinbase_container {
  height: 0;
  .coinbase-button {
    height: 0 !important;
    visibility: hidden;
  }
}

.agreement-text-style {
  vertical-align: middle;
  font-size: 0.8rem;
  
  line-height: 1.4;
  color: black;
  display: flex;
  align-items: center;
  margin-top: 1rem;

  a {
    display: contents;
    text-decoration: underline;
    color: black;
  }
}

.spinner {
  margin: 100px auto 0;
  width: 120px;
  text-align: center;
}

.spinner > div {
  width: 18px;
  height: 18px;
  background-color: rgba(0, 0, 0, 0.3);
  margin: 0 5px;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner.light > div {
  background-color: rgba(255, 255, 255, 0.3);
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes check {
  0% {
    height: 0;
    width: 0;
  }
  25% {
    height: 0;
    width: 10px;
  }
  50% {
    height: 20px;
    width: 10px;
  }
}
.checkbox {
  background-color: $background-color;
  display: inline-block;
  height: 28px;
  margin: 0 0.25em;
  width: 28px;
  border-radius: 3px;
  border: 1px solid $primary-color;
  float: left;
  margin-right: 10px;
}
.checkbox span {
  display: block;
  height: 30px;
  position: relative;
  width: 30px;
  padding: 0;
}
.checkbox span:after {
  -moz-transform: scaleX(-1) rotate(135deg);
  -ms-transform: scaleX(-1) rotate(135deg);
  -webkit-transform: scaleX(-1) rotate(135deg);
  transform: scaleX(-1) rotate(135deg);
  -moz-transform-origin: left top;
  -ms-transform-origin: left top;
  -webkit-transform-origin: left top;
  transform-origin: left top;
  border-right: 4px solid $background-color;
  border-top: 4px solid $background-color;
  content: "";
  display: block;
  height: 20px;
  left: 3px;
  position: absolute;
  top: 15px;
  width: 10px;
}

.checkbox input {
  display: none;
}
.checkbox input:checked + span:after {
  -webkit-animation: check 0.8s;
  -moz-animation: check 0.8s;
  -o-animation: check 0.8s;
  animation: check 0.8s;
  border-color: #555;
}
.checkbox input:checked + .default:after {
  border-color: $primary-color;
}
